(function () {
   when($('showTranslationsToggle'), toggle => {
      toggle.addEvent('click', function (ev) {
         let showAll = this.checked;

         let request = new Request.API_2_0('user/showAllTranslations', {
            method: 'PUT',
            onSuccess: function () {
               window.location.reload();
            },
         }).send({ showAll: showAll });

         LoadingIndicator.withPromise(request, { hideSuccessMessage: true });
      });
   });
})();
